import { render, staticRenderFns } from "./NotFound.vue?vue&type=template&id=617361f8&scoped=true"
import script from "./NotFound.vue?vue&type=script&lang=js"
export * from "./NotFound.vue?vue&type=script&lang=js"
import style0 from "./NotFound.vue?vue&type=style&index=0&id=617361f8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "617361f8",
  null
  
)

export default component.exports